import {Currency} from 'app/blocks/model/currency.model';
import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Resource} from 'app/constants/resource';
import {ICurrency} from 'app/blocks/model/currency.model';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Select} from '@ngxs/store';
import {Observable, BehaviorSubject, Subject} from 'rxjs';
import {Symbol} from 'app/blocks/model/symbol.model';
import {takeUntil} from 'rxjs/operators';
import {DisplayUtilsService} from 'app/common/display-utils-service';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable({
    providedIn: 'root'
})
export class CurrencyService extends EntityCrudService<ICurrency> implements OnDestroy {
    @Select((state) => state.common.defaultCurrency)
    currency$: Observable<ICurrency>;

    private _currencySymbolSubject = new BehaviorSubject<Symbol>(null);
    public currencySymbol$ = this._currencySymbolSubject.asObservable();

    protected _unsubscribeAll: Subject<void> = new Subject();

    constructor(
        http: HttpClient,
        private _displayUtilsService: DisplayUtilsService
    ) {
        super(http, Resource.CURRENCIES, (obj?) => new Currency(obj));
        this._initCurrency();
    }

    private _initCurrency(): void {
        this.currency$.pipe(takeUntil(this._unsubscribeAll)).subscribe((defaultCurrency) => {
            if (defaultCurrency) {
                const decodedCurrencySymbol = this._displayUtilsService.decodeHTMLEntity(defaultCurrency.htmlCode);
                const defaultCurrencySymbol = new Symbol(decodedCurrencySymbol, defaultCurrency.prefix);
                this._currencySymbolSubject.next(defaultCurrencySymbol);
            }
        });
    }

    getCurrenyDenominationsForCompanyLevelCurrency = (): any => {
        return this.http.get(SERVER_API_URL + 'api/currency-denominations/', {observe: 'response'}).toPromise();
    };

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
