import {IFranchise} from 'app/blocks/model/franchise.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface IBaseFranchiseEntity extends IBaseEntity {
    assignedFranchise?: IFranchise;
    editable?: boolean;
    owningFranchise?: IFranchise;

    toFormObject(): any;
    clone(): IBaseEntity;
}

export abstract class BaseFranchiseEntity extends BaseEntity implements IBaseFranchiseEntity {
    editable = true;
    assignedFranchise?: IFranchise;
    owningFranchise?: IFranchise;
}
