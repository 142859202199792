import {IEntityUrlProvider} from 'app/blocks/routing/entity-url-provider';
import {AbstractResource} from 'app/blocks/resource/abstract-resource';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IEntityNavigator} from 'app/blocks/routing/navigator';

export abstract class AbstractEntityResource<T> extends AbstractResource<T> {
    constructor(
        name: Resource,
        api: EntityCrudService<T>,
        navigator: IEntityNavigator,
        urlProvider: IEntityUrlProvider,
        protected _entityCreator: (entity?) => T,
        protected _entityDiscriminator: (entity) => any = (entity) => entity.id,
        public icon: string = 'ads_click',
        public icon2?: string
    ) {
        super(name, api, navigator, urlProvider);
    }

    get navigate(): IEntityNavigator {
        return this._navigator as IEntityNavigator;
    }

    get url(): IEntityUrlProvider {
        return this._urlProvider as IEntityUrlProvider;
    }

    newInstance(entity?): T {
        return this._entityCreator(entity);
    }

    getEntityDiscriminator(entity: T): any {
        return this._entityDiscriminator(entity);
    }
}
