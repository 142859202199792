import {Router} from '@angular/router';

export interface INavigator {
    toHome(): void;
}

export interface IEntityNavigator extends INavigator {
    toList(parmas?: any): void;
    toView(id: number): void;
    toEdit(id: number): void;
    toNew(params?: any): void;
    toCustom(id: number, urlParam: string, params?: any): void;
}

export class EntityNavigator implements IEntityNavigator {
    private _resourcePath;

    constructor(
        private _basePath: string,
        private _resouceName: string,
        private _router: Router
    ) {
        this._resourcePath = this.camelCaseToDashed(_resouceName);
    }

    camelCaseToDashed(str): string {
        return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
    }

    toHome(): void {
        this.toList();
    }

    toList(params?: any): void {
        this._router.navigate(['/', ...this._basePath.split('/').filter((part) => part !== ''), this._resourcePath], params);
    }

    toView(id: number): void {
        this._router.navigate(['/', ...this._basePath.split('/').filter((part) => part !== ''), this._resourcePath, id]);
    }

    toEdit(id: number): void {
        this._router.navigate(['/', ...this._basePath.split('/').filter((part) => part !== ''), this._resourcePath, id, 'edit']);
    }

    toNew(params?: any): void {
        this._router.navigate(['/', ...this._basePath.split('/').filter((part) => part !== ''), this._resourcePath, 'new'], params);
    }

    toCustom(id: number, urlParam: string, params?: any): void {
        this._router.navigate(['/', ...this._basePath.split('/').filter((part) => part !== ''), this._resourcePath, id, urlParam], params);
    }
}
